<script lang="ts">
  import { Button, Loading } from 'carbon-components-svelte'
  import Close16 from 'carbon-icons-svelte/lib/Close.svelte'
  import { state } from '../../../store/state'
  import { NullNewContentData } from '../../../store/new-content'
  import { dirtyPopup, isDirty } from './new-content-tab'
  import { contentCreation } from '../toolbar/toolbar'

  const { newContent } = state
  const { data } = newContent

  const isLoading = state.isLoading

  const handleClose = async () => {
    if (await isDirty(newContent)) {
      return dirtyPopup(state, newContent)
    }

    await newContent.removeDraft()
    newContent.clear()
  }

  const handleSelect = () => {
    newContent.select()
  }

  $: show = $isLoading || !($data instanceof NullNewContentData)
</script>

{#if show}
  <div class="new-content-tab-container" class:active={$data?.selected}>
    {#if $isLoading}
      <button class="new-content-tab border-r opacity-50">
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row items-center">
            <span class="ml-2">Loading...</span>
          </div>
        </div>
      </button>
    {:else}
      <button on:click={handleSelect} class="new-content-tab" class:active={$data.selected}>
        <div class="flex flex-row justify-between w-full">
          <div class="flex flex-row items-center">
            {#if $contentCreation}
              <div class="pl-2">
                <Loading withOverlay={false} small />
              </div>
            {/if}
            <span class="ml-2">{$data.name}</span>
          </div>
          <Button
            on:click={handleClose}
            class="btn-action"
            kind="ghost"
            icon={Close16}
            tooltipPosition="left"
          />
        </div>
      </button>
    {/if}
  </div>
{/if}

<style lang="postcss">
  .new-content-tab-container {
    @apply flex flex-row w-48 items-end;
  }
  .new-content-tab {
    @apply bg-transparent w-full text-white;
    min-height: calc(var(--bars-size) - var(--topbar-margin));
    max-height: calc(var(--bars-size) - var(--topbar-margin));
  }

  .active {
    @apply bg-background text-black rounded-t-custom;
  }

  .new-content-tab:not(.active) {
    --cds-icon-01: white;
    border-left: 1px solid;
  }

  .new-content-tab :global(.bx--btn) {
    background: transparent;
    border-width: 0;
    outline-width: 0;
    min-height: 100%;
    max-height: 100%;
  }

  .new-content-tab :global(.bx--tooltip__trigger:focus.bx--tooltip--a11y),
  .new-content-tab :global(.bx--tooltip__trigger:focus.bx--tooltip--a11y::before),
  .new-content-tab :global(.bx--tooltip__trigger:hover.bx--tooltip--a11y::before),
  .new-content-tab :global(.bx--tooltip__trigger:hover .bx--assistive-text) {
    display: none;
  }
</style>
